import React from 'react';
import styled from 'styled-components';

const StyledMessage = styled.div`
  max-width: 19rem;
  background-color: var(--color-iris);
  color: var(--color-white);
  border-radius: 1rem;
  padding: 1rem 1.5rem;
  font-family: var(--font-osynlig);
  font-feature-settings: 'ss01' on;
  font-weight: normal;
  font-size: 0.75em;
  flex: 0 0 auto;
  height: auto;
  line-height: 1.666666667;
  position: relative;
  margin-right: 1rem;
  flex-shrink: 1;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0.75rem;
    right: -0.5rem;
    width: 0;
    height: 0;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-left: 16px solid var(--color-iris);
  }

  p {
    line-height: 1.7;
    margin-top: 0;
  }
`;

const Message: React.FC = ({ children }) => <StyledMessage>{children}</StyledMessage>;

export default Message;
