/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// Prevents the prompt 'add to homescreen' to appear on mobile
export const onClientEntry = () => {
  window.addEventListener('beforeinstallprompt', e => {
    e.preventDefault();
  });
};
