import React from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import Checkbox from '$components/Checkbox';
import { NewsletterCheckboxProps } from './types';

const StyledCheckbox = styled(Checkbox)`
  max-width: 14.5em;
  margin: 0 auto;
  color: var(--color-white);

  @media screen and (min-width: 480px) and (max-width: 767px) {
    margin-left: 0;
  }
`;

const NewsletterCheckbox: React.FC<NewsletterCheckboxProps> = ({ onChange, checked }) => (
  <StyledCheckbox name="termsAccepted" onChange={onChange} checked={checked} id="terms" required>
    Jag godkänner att ta emot utskick i enlighet med{' '}
    <AniLink cover direction="left" to="/integritetspolicy">
      integritetspolicyn
    </AniLink>
  </StyledCheckbox>
);

export default NewsletterCheckbox;
