import React from 'react';
import styled from 'styled-components';
import scalingSize from '$utils/scalingSize';

const StyledHeading = styled.h2`
  max-width: var(--max-width-small-wrap);
  color: var(--color-white);
  text-align: center;
  justify-self: center;
  margin: 0;
  width: 15em;
  ${scalingSize('font-size', 24, 48)};
  line-height: 1.25;
  letter-spacing: -0.02em;

  @media screen and (min-width: 480px) {
    grid-column: 1 / 3;
  }

  @media screen and (min-width: 768px) {
    grid-column: 1 / 4;
  }
`;

const NewsletterHeading: React.FC = ({ children }) => <StyledHeading>{children}</StyledHeading>;

export default NewsletterHeading;
