import React from 'react';
import styled from 'styled-components';
import Markdown from '$components/Markdown';
import { ColumnProps } from './types';
import Paragraph from './commonStyles';

const StyledNav = styled.nav`
  p {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ${Paragraph}

    a {
      font-size: unset;
      line-height: 3rem;

      &:hover {
        color: var(--color-cloud3);
      }

      @media screen and (min-width: 640px) {
        line-height: 1.625rem;
      }
    }

    br {
      display: none;
    }
  }

  h3,
  h5 {
    color: var(--color-cloud3);
    font-size: 1em;
    margin: 0 0 0.875em;
  }

  a {
    ${Paragraph}
    border-bottom: none;

    span {
      border-bottom: none;
    }

    &:hover {
      border-bottom: none;
    }
  }
`;

const Nav: React.FC<ColumnProps> = ({ content }) => (
  <StyledNav>
    <Markdown content={content} />
  </StyledNav>
);

export default Nav;
