import React from 'react';
import styled from 'styled-components';
import { LabelProps } from './types';
import { Color } from '$utils/Color';

const svg = (color) =>
  `url('data:image/svg+xml;utf8,${encodeURIComponent(
    `<svg xmlns="http://www.w3.org/2000/svg" fill="none" width="14px" height="10px" viewBox="0 0 14 10"><path stroke="${color}" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12.33 1L5 8.33 1.67 5"/></svg>`
  )}')`;
const size = 1.25;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  font-family: var(--font-sans);
  font-weight: 300;

  &:before {
    display: block;
    flex: 0 0 ${size}rem;
    height: ${size}rem;
    background-color: var(--color-white);
    background-position: 50%;
    background-repeat: no-repeat;
    content: '';
    margin-right: 0.75rem;
    transition: background 0.3s ease;
    border-radius: 0.25rem;
  }

  .checkbox:checked ~ &:before {
    background-image: ${svg(Color.slate)};
  }

  &:hover:before {
    background-image: ${svg(Color.slate5)};
  }
`;

const Text = styled.span`
  font-size: 0.75em;
  line-height: 1.25rem;
  letter-spacing: -0.01em;

  a {
    color: currentColor;
    text-decoration: underline;
  }
`;

const Label: React.FC<LabelProps> = ({ id, children }) => (
  <StyledLabel htmlFor={id}>
    <Text>{children}</Text>
  </StyledLabel>
);

export default Label;
