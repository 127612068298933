import React from 'react';
import styled from 'styled-components';
import Logo from '$components/Logo';
import { WrapMax } from '$components/Wraps';
import dynamicPadding2x from '$utils/dynamicPaddingx2';
import { FooterProps } from './types';
import Address from './Address';
import Nav from './Nav';

const StyledLogo = styled(Logo)`
  display: none;

  @media screen and (min-width: 1250px) {
    display: block;
  }
`;

const Outer = styled.footer`
  ${dynamicPadding2x};
  background-color: var(--color-slate);
  color: var(--color-white);
  z-index: 1;
`;

const Inner = styled(WrapMax)`
  display: grid;
  grid-gap: 2.5em 6.388888889vw;
  justify-content: space-between;
  align-items: start;
  grid-template-columns: 1fr;

  @media screen and (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 860px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (min-width: 1250px) {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 2.5em 5rem;
  }
`;

const Footer: React.FC<FooterProps> = ({ columns: { Column1, Column2, Column3, Column4 } }) => (
  <Outer>
    <Inner>
      <StyledLogo size={2} inverted />
      <Address content={Column1} />
      <Address content={Column2} />
      <Address content={Column3} />
      <Nav content={Column4} />
    </Inner>
  </Outer>
);

export default Footer;
