import React from 'react';
import styled from 'styled-components';
import { ExternalLinkProps } from './types';
import ExternalLinkIcon from '$assets/icons/external-link.svg';

const StyledExternal = styled.a<{ icon?: string }>`
  appearance: none;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  color: var(--color-slate);
  ${(props) =>
    props.icon &&
    `
    background-image: url('icons/${props.icon}.svg');
  `}
  line-height: inherit;

  span {
    border-bottom: 1px solid var(--color-citrus);
    padding-bottom: 0.25rem;
  }

  svg {
    transition: transform 350ms ease;
    stroke: var(--color-slate);
    font-size: 0.875em;
    position: relative;
    top: 0.25em;
  }

  .hover &,
  &:hover,
  &:focus {
    color: var(--color-slate);

    span {
      border-color: var(--color-slate);
    }
  }
`;

const ExternalLink: React.FC<ExternalLinkProps> = ({
  text = '',
  target,
  href,
  className,
  onMouseEnter,
  onMouseLeave,
  extraStyle,
}) => (
  <StyledExternal
    className={className}
    href={href}
    target={target}
    rel={target && target === '_blank' ? 'noopener noreferrer' : null}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    style={extraStyle}
  >
    <span>{text}</span>
    {href.indexOf('mailto:') === -1 && <ExternalLinkIcon className="icon" viewBox="0 0 24 24" />}
  </StyledExternal>
);

export default ExternalLink;
