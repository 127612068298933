import React, { createRef, useState } from 'react';
import styled from 'styled-components';
import Button from '$components/Button';
import dynamicPadding2x from '$utils/dynamicPaddingx2';
import Inner from './Inner';
import NewsletterHeading from './NewsletterHeading';
import NewsletterTextInput from './NewsletterTextInput';
import NewsletterCheckbox from './NewletterCheckbox';

const Outer = styled.section`
  background-color: var(--color-iris2);
  ${dynamicPadding2x};
  position: relative;
`;

const StyledButton = styled(Button)`
  margin: 0 auto;
`;

const Newsletter: React.FC = () => {
  const formRef = createRef<HTMLFormElement>();

  const [formState, setFormState] = useState({
    email: '',
    termsAccepted: false,
  });

  const onFormChange = (ev) => {
    setFormState({
      ...formState,
      [ev.target.name]: ev.target.type === 'checkbox' ? ev.target.checked : ev.target.value,
    });
  };

  const onSubmit = () => {
    formRef.current.submit();

    setTimeout(() => {
      setFormState({
        email: '',
        termsAccepted: false,
      });
    }, 500);
  };

  return (
    <Outer>
      <form
        ref={formRef}
        action="https://osynlig.us2.list-manage.com/subscribe/post?u=51f766054e084b015dd7f0252&amp;id=aa00536768"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        target="_blank"
        // React typing seems to be missing https://github.com/microsoft/TypeScript/issues/40940
        rel="noopener"
        onSubmit={onSubmit}
      >
        <Inner>
          <NewsletterHeading>Få våra bästa tips och spaningar direkt till mailen</NewsletterHeading>
          <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
            <input type="text" name="b_51f766054e084b015dd7f0252_aa00536768" tabIndex={-1} defaultValue="" />
          </div>
          <NewsletterTextInput onChange={onFormChange} value={formState.email} />
          <NewsletterCheckbox onChange={onFormChange} checked={formState.termsAccepted} />

          <StyledButton theme="secondary" showArrow>
            Prenumerera
          </StyledButton>
        </Inner>
      </form>
    </Outer>
  );
};

export default Newsletter;
