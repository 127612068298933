import React from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { InternalLinkProps } from './types';

const StyledInternalLink = styled(AniLink)`
  color: var(--color-slate);
  border-bottom: 1px solid var(--color-citrus);
  transition: border 0.2s ease;

  &:hover {
    border-bottom: 1px solid var(--color-slate);
  }
`;

const InternalLink: React.FC<InternalLinkProps> = ({ children, cover, direction, to, bg }) => (
  <StyledInternalLink cover={cover} direction={direction} to={to} bg={bg}>
    {children}
  </StyledInternalLink>
);

export default InternalLink;
