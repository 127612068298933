import React from 'react';
import styled from 'styled-components';

const Meta = styled.ul`
  font-size: 0.75em;
  color: var(--color-slate);
  font-family: var(--font-serif);
  margin: 0.25rem 0;
  padding: 0;
`;

const MetaContent: React.FC = ({ children }) => <Meta>{children}</Meta>;

export default MetaContent;
