import React from 'react';
import { CheckboxProps } from './types';
import Box from './Box';
import Label from './Label';

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  required = false,
  id,
  children,
  value,
  className,
  onChange,
  checked = false,
}) => (
  <div className={className}>
    <Box id={id} type="checkbox" name={name} value={value} required={required} checked={checked} onChange={onChange} />
    <Label id={id}>{children}</Label>
  </div>
);

export default Checkbox;
