import React from 'react';
import ReactMarkdown from 'react-markdown';
import Paragraph from '$components/Paragraph';
import Excerpt from '$components/Excerpt';
import Heading1 from '$components/Heading1';
import Heading2 from '$components/Heading2';
import Heading3 from '$components/Heading3';
import Link from '$components/Link';
import Blockquote from './Blockquote';
import UnorderedList from './UnorderedList';
import OrderedList from './OrderedList';

const ReactMarkdownWrapper: React.FC<{ content: string }> = ({ content }) => (
  <ReactMarkdown
    components={{
      p: ({ children }) => <Paragraph markdown>{children}</Paragraph>,
      blockquote: ({ children }) => <Blockquote>{children}</Blockquote>,
      a: ({ href, children }) => (
        <Link to={href} cover direction="left" bg="var(--color-citrus5)">
          {children}
        </Link>
      ),
      ul: ({ children }) => <UnorderedList>{children}</UnorderedList>,
      ol: ({ children }) => <OrderedList>{children}</OrderedList>,
      h1: ({ children }) => <Heading1 markdown>{children}</Heading1>,
      h2: ({ children }) => <Heading2 markdown>{children}</Heading2>,
      h3: ({ children }) => <Heading3 markdown>{children}</Heading3>,
      h5: ({ children }) => <Excerpt markdown>{children}</Excerpt>,
    }}
  >
    {content}
  </ReactMarkdown>
);

export default ReactMarkdownWrapper;
