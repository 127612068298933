import React from 'react';
import styled from 'styled-components';
import { TextInputProps } from './types';
import SharedGridStyle from './commonStyles';

const StyledInput = styled.input<{ icon: boolean; rounded: boolean }>`
  appearance: none;
  border: 0;
  border-radius: 0;
  line-height: 1.75;
  padding: 0.625em 1.125em;
  font-family: var(--font-sans);
  color: var(--color-slate);
  outline: none;
  box-shadow: inset 0 0 0 2px transparent;
  ${SharedGridStyle};

  ${(props) =>
    props.icon &&
    `
    padding-left: 3.25rem;
  `};

  ${(props) =>
    props.rounded &&
    `
    padding-left: 3.875rem;
    border-radius: 1.5rem;
  `};

  width: 100%;

  &:before {
    width: 1em;
    height: 1em;
    background-color: red;
    content: '';
  }

  &:hover {
    box-shadow: inset 0 0 0 2px var(--color-citrus);
  }

  &:focus {
    box-shadow: inset 0 0 0 2px var(--color-slate);
  }

  &.error {
    box-shadow: inset 0 0 0 2px var(--color-begonia);
  }

  &::placeholder {
    color: var(--color-slate3);
  }
`;

const Input: React.FC<TextInputProps> = ({
  type,
  name,
  placeholder,
  Icon,
  required,
  autocomplete,
  onChange,
  value,
  rounded,
}) => (
  <StyledInput
    type={type}
    name={name}
    placeholder={placeholder}
    aria-label={placeholder}
    icon={Boolean(Icon)}
    required={required}
    autoComplete={autocomplete}
    onChange={onChange}
    value={value}
    rounded={rounded}
  />
);

export default Input;
