import React from 'react';
import styled, { css } from 'styled-components';
import scalingSize from '$utils/scalingSize';
import { Heading3Props } from './types';

const StyledHeading3 = styled.h3<Heading3Props>`
  color: var(--color-slate);
  line-height: 1.333333333;
  letter-spacing: -0.02em;
  margin: 0 0 1rem;

  ${(props) =>
    props.isList
      ? css`
          color: var(--color-slate2);
          ${scalingSize('font-size', 16, 32)};
        `
      : null};

  ${(props) =>
    props.markdown
      ? css`
          font-size: 1.25rem;
          margin: 3rem 0 1rem;
        `
      : null}
`;

const Heading3: React.FC<Heading3Props> = ({ children, isList, markdown }) => (
  <StyledHeading3 isList={isList} markdown={markdown}>
    {children}
  </StyledHeading3>
);

export default Heading3;
