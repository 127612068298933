import React from 'react';
import styled from 'styled-components';
import { TextInputProps } from './types';
import SharedGridStyle from './commonStyles';
import Input from './Input';

const Outer = styled.div<{ rounded: boolean }>`
  display: grid;
  position: relative;
  stroke: var(--color-slate);
  grid-template-columns: 1fr;

  svg {
    ${SharedGridStyle};
    margin-left: 1rem;
    align-self: center;

    ${(props) =>
      props.rounded &&
      `
    margin-left: 1.75rem;
   
  `};
  }
`;

const TextInput: React.FC<TextInputProps> = ({
  type,
  placeholder,
  autocomplete,
  required,
  Icon,
  name,
  className,
  onChange,
  value,
  rounded,
}) => (
  <Outer className={className} rounded={rounded}>
    <Input
      type={type}
      placeholder={placeholder}
      autocomplete={autocomplete}
      required={required}
      Icon={Icon}
      name={name}
      onChange={onChange}
      value={value}
      rounded={rounded}
    />
    {Icon && <Icon className="icon" viewBox="0 0 24 24" />}
  </Outer>
);

export default TextInput;
