import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import getImageData from '$utils/getImageData';
import Paragraph from '$components/Paragraph';
import Button from '$components/Button';
import { Item } from './types';

const StyledItem = styled.div<{ single: boolean }>`
  color: var(--color-slate);
  display: grid;
  min-height: 100px;
  grid-template-areas:
    'title image'
    'content content';
  grid-template-columns: auto 4em;
  grid-gap: 1.5em;
  align-items: center;

  @media screen and (min-width: 640px) {
    grid-template-areas:
      'image .'
      'image title'
      'image content'
      'image .';
    grid-template-columns: ${(props) => (props.single ? '8.888888889vw' : '12.777777778vw')} 356fr;
    grid-gap: 0 2.222222222vw;
  }
  @media screen and (min-width: 1680px) {
    grid-template-columns: ${(props) => (props.single ? '9.33em' : '13.42em')} 356fr;
    grid-gap: 0 2.333333333em;
  }
`;

const ImageWrap = styled.div`
  width: 4em;
  height: 4em;
  grid-area: image;

  @media screen and (min-width: 640px) {
    grid-row: image-start / image-end;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }

  img {
    width: 100%;
    border-radius: 90em;
  }
`;

const Content = styled.div<{ single: boolean }>`
  grid-column: content-start / content-end;
  grid-area: content;
  ${(props) =>
    props.single &&
    `
    max-width: 68.076923077%;
  `};
`;

const Title = styled.h2`
  color: var(--color-slate);
  font-size: 1.5em;
  line-height: 2rem;
  letter-spacing: -0.02em;
  margin: 0;
  grid-area: title;

  @media screen and (min-width: 640px) {
    font-size: 1.25em;
    margin-bottom: 0.5rem;
  }
`;

const CtaItem: React.FC<{ item: Item; single?: boolean }> = ({ item, single = false }) => (
  <AniLink to={item.Link.URL} bg="var(--color-citrus5)" cover direction="left" key={item.Link.URL}>
    <StyledItem single={single}>
      {item.Image && (
        <ImageWrap>
          <GatsbyImage image={getImageData(item.Image.imageFile)} alt={item.Image.alternativeText} />
        </ImageWrap>
      )}

      <Title>{item.Heading}</Title>

      <Content single={single}>
        <Paragraph cta>{item.Text}</Paragraph>
        {item.Link && (
          <Button theme="ghost" showArrow>
            {item.Link.Title}
          </Button>
        )}
      </Content>
    </StyledItem>
  </AniLink>
);

export default CtaItem;
