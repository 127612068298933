import React from 'react';
import InternalLink from '$components/InternalLink';
import ExternalLink from '$components/ExternalLink';
import { LinkProps } from './types';

const Link: React.FC<LinkProps> = ({ to, children, bg, cover, direction }) => {
  // if first character in link is '/' we have a internal link
  if (to.charAt(0) !== '/') {
    return <ExternalLink href={to} text={children[0]} />;
  }
  return (
    <InternalLink cover={cover} direction={direction} to={to} bg={bg}>
      {children}
    </InternalLink>
  );
};

export default Link;
