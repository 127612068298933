import React from 'react';
import styled, { css } from 'styled-components';
import scalingSize from '$utils/scalingSize';
import { Heading2Props } from './types';

const StyledHeading2 = styled.h2<Heading2Props>`
  color: var(--color-slate);
  letter-spacing: -0.02em;

  ${(props) =>
    props.hero
      ? css`
          margin: 0;
          ${scalingSize('font-size', 20, 32)};

          @media screen and (min-width: 860px) {
            text-align: right;
            grid-column: 1;
          }
        `
      : null};

  ${(props) =>
    props.process
      ? css`
          margin: 0;
          ${scalingSize('font-size', 24, 48)}
          @media screen and (min-width: 860px) {
            text-align: center;
            grid-column: 1;
          }
        `
      : null}

  ${(props) =>
    props.notFoundPage
      ? css`
          margin: 0 0 1rem;

          @media screen and (min-width: 860px) {
            text-align: center;
            grid-column: 1;
          }
        `
      : null}

  ${(props) =>
    props.startPage
      ? css`
          margin: 0;
          ${scalingSize('font-size', 20, 32)};

          @media screen and (min-width: 860px) {
            text-align: right;
            grid-column: 1;
          }
        `
      : null};

  ${(props) =>
    props.markdown
      ? css`
          ${scalingSize('font-size', 24, 48)};
          margin: 3rem 0 1rem;
        `
      : null};

  ${(props) =>
    props.large
      ? css`
          ${scalingSize('font-size', 20, 32)};
        `
      : null};
`;

const Heading2: React.FC<Heading2Props> = ({
  children,
  hero,
  startPage,
  cta,
  process,
  markdown,
  notFoundPage,
  large,
}) => (
  <StyledHeading2
    hero={hero}
    startPage={startPage}
    cta={cta}
    process={process}
    markdown={markdown}
    notFoundPage={notFoundPage}
    large={large}
  >
    {children}
  </StyledHeading2>
);

export default Heading2;
