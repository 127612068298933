import React from 'react';
import styled from 'styled-components';
import { WrapMax } from '$components/Wraps';

const StyledMenuWrapper = styled(WrapMax)`
  display: grid;
  grid-template-areas: 'logo menu contact';
  grid-auto-columns: max-content;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;

  @media screen and (max-width: 1139px) {
    grid-template-areas: 'logo menu mobile';
  }

  @media screen and (max-width: 1022px) {
    grid-template-areas: 'logo mobile';
  }
`;

const MenuWrapper: React.FC = ({ children }) => <StyledMenuWrapper>{children}</StyledMenuWrapper>;

export default MenuWrapper;
