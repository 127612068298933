import React from 'react';
import styled from 'styled-components';

const StyledImage = styled.div`
  transition: all 0.22s cubic-bezier(0.51, -0.31, 0.57, 1.39);
  position: relative;
  clip-path: url(#contact);
  width: 100%;

  img {
    backface-visibility: hidden;
    transform: translateZ(0);
  }
`;

const ImageWrapper: React.FC = ({ children }) => <StyledImage>{children}</StyledImage>;

export default ImageWrapper;
