import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Menu from '$assets/icons/menu.svg';
import { MobileMenuButtonProps } from './types';

const StyledMobileMenuButton = styled.button<{ active: boolean; dark: boolean }>`
  width: 1.5rem;
  height: 1.5rem;
  background: none;
  appearance: none;
  border: 0;
  position: relative;
  padding: 0;
  user-select: none;

  svg {
    path {
      transition: transform 400ms cubic-bezier(0, 0.93, 0.74, 0.77);
      stroke: ${(props) => (props.dark ? 'var(--color-slate)' : 'var(--color-white)')};
      stroke-width: 1.5;
      stroke-linecap: round;
      vector-effect: non-scaling-stroke;
    }

    path:first-child {
      transform: ${(props) => (props.active ? 'translateX(-0.43rem)' : 'translateX(0)')};
    }
  }

  @media screen and (min-width: 1023px) {
    display: none;
  }
`;

const MobileMenuButton: React.FC<MobileMenuButtonProps> = ({ menuButtonClicked, mobileMenuActive, label, dark }) => {
  const [menuActive, setMenuActive] = useState(mobileMenuActive);

  useEffect(() => {
    if (mobileMenuActive) {
      setMenuActive(true);
    }
    if (!mobileMenuActive) {
      setMenuActive(false);
    }
  }, [mobileMenuActive]);

  return (
    <StyledMobileMenuButton onClick={menuButtonClicked} aria-label={label} active={menuActive} dark={dark}>
      <Menu />
    </StyledMobileMenuButton>
  );
};
export default MobileMenuButton;
