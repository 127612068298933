import { withCookies } from 'react-cookie';
import React, { useEffect, useState, useRef } from 'react';
import throttle from 'lodash.throttle';
import styled from 'styled-components';
import Paragraph from '$components/Paragraph';
import Button from '$components/Button';
import { Wrap } from '$components/Wraps';
import Link from '$components/Link';
import { CookieWarningProps } from './types';

const COOKIE_NAME = 'osynlig-allow-cookies';

const TIMING = 420;

const FadeWrap = styled.div`
  order: -40;
  overflow: hidden;
  transition: height ${TIMING}ms cubic-bezier(0.31, 0.09, 0.42, 0.97),
    top 0.3s cubic-bezier(0.31, 0.09, 0.42, 0.97) 0.12s;
  position: fixed;
  top: 0;
  z-index: 1100;

  &.notransition {
    transition: none;
  }
`;

const Outer = styled.div`
  width: 100vw;
  background-color: var(--color-champ4);
  color: var(--color-slate);
  padding: 1.25rem 0;
  font-size: 0.875em;
`;

const Inner = styled(Wrap)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (min-width: 640px) {
    flex-direction: row;
  }
`;

const StyledLink = styled(Link)`
  color: var(--color-slate);
  border-bottom: 1px solid var(--color-citrus);
  transition: border 0.3s ease;

  &:hover,
  &:focus {
    border-color: var(--color-slate);
  }
`;

const CookieWarning: React.FC<CookieWarningProps> = ({
  cookies,
  cookieBarHeight,
  setCookieBarHeight,
  visibleHeader,
}) => {
  const heightRef = useRef(null);
  const [render, setRender] = useState(cookies.get(COOKIE_NAME) !== 'true');
  const [state, setState] = useState(0);

  const onResize = () => {
    if (render) {
      const el = heightRef.current;
      const currentHeight = el?.clientHeight;

      if (cookieBarHeight !== currentHeight) {
        setCookieBarHeight(currentHeight ?? 0);
      }
    }
  };

  const handleOnResize = () => {
    setState((prevState) => prevState + 1);
  };

  useEffect(() => {
    onResize();
  }, [state]);

  const addListener = () => {
    window.addEventListener('resize', throttle(handleOnResize, 100));
  };

  const removeListener = () => {
    window.removeEventListener('resize', throttle(handleOnResize, 100));
  };

  useEffect(() => {
    setState((prevState) => prevState + 1);
    addListener();
    if (render) {
      const el = heightRef.current;
      const currentHeight = el.clientHeight;
      setCookieBarHeight(currentHeight ?? 0);
    }

    return () => {
      removeListener();
    };
  }, []);

  const onAccept = () => {
    // Cookie saved for 3 months
    const date = new Date();
    date.setMonth(date.getMonth() + 3);
    const domain = process.env.GATSBY_COOKIE_DOMAIN ?? '.osynlig.se';

    cookies.set(COOKIE_NAME, true, { path: '/', expires: date, domain });
    setCookieBarHeight(0);

    setTimeout(() => {
      setRender(false);
      removeListener();
    }, TIMING);
  };

  return (
    render && (
      <FadeWrap style={{ height: `${cookieBarHeight}px`, top: visibleHeader ? '0px' : `${-cookieBarHeight}px` }}>
        <Outer ref={heightRef}>
          <Inner>
            <Paragraph cookie>
              Vi använder cookies för mät- och analysändamål samt marknadsföring.{' '}
              <StyledLink bg="var(--color-citrus5)" cover direction="left" to="/integritetspolicy">
                Läs mer i vår cookiepolicy
              </StyledLink>
              .
            </Paragraph>
            <Button extraStyle={{ whiteSpace: 'nowrap' }} theme="secondary" size="small" onClick={onAccept}>
              Jag godkänner
            </Button>
          </Inner>
        </Outer>
      </FadeWrap>
    )
  );
};
export default withCookies(CookieWarning);
