import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import getImageData from '$utils/getImageData';
import { ContactImageProps, FigureProps } from './types';
import { Color, ContactCardColor } from '$utils/Color';
import ImageWrapper from './ImageWrapper';

const translate = '12.5%';
const contactCardColorKeys = Object.keys(ContactCardColor);
const Figure = styled.figure<FigureProps>`
  margin: 0;
  position: relative;
  flex: 0 0 100%;

  ${(props) =>
    props.align &&
    `
    transform: translateY(-11.763295449%);
  `}

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding-bottom: 100%;
    content: '';
    border-radius: 999em;
    background-color: ${(props) => props.background};
    transition: all 0.22s cubic-bezier(0.51, -0.31, 0.57, 1.39);
    transform: translateY(${translate});
  }
`;

const ContactImage: React.FC<ContactImageProps> = ({ image, backgroundColor, align }) => {
  const [randomBackground, setRandomBackground] = useState(null);

  useEffect(() => {
    if (backgroundColor) {
      setRandomBackground(Color[backgroundColor]);
    } else {
      setRandomBackground(
        ContactCardColor[contactCardColorKeys[Math.floor(Math.random() * contactCardColorKeys.length)]]
      );
    }
  }, []);

  return (
    <Figure align={align} background={randomBackground}>
      <ImageWrapper>
        {image.imageFile ? (
          <GatsbyImage image={getImageData(image.imageFile)} alt={image.alternativeText} />
        ) : (
          <StaticImage src="../../assets/images/shadow.png" alt="" placeholder="blurred" />
        )}
      </ImageWrapper>
    </Figure>
  );
};

export default ContactImage;
