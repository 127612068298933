import React from 'react';
import styled, { css } from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { ButtonProps, ButtonType, StylesProps } from './types';
import ArrowRight from '$assets/icons/arrow-right.svg';

const themes = {
  default: {
    default: 'var(--color-iris2)',
    secondary: 'var(--color-slate)',
    ghost: 'transparent',
    ghostWhite: 'transparent',
  },
  hover: {
    default: 'var(--color-iris)',
    secondary: 'var(--color-slate2)',
    ghost: 'transparent',
    ghostWhite: 'transparent',
  },
  active: {
    default: 'var(--color-slate)',
    secondary: 'var(--color-slate)',
    ghost: 'transparent',
    ghostWhite: 'transparent',
  },
  color: {
    default: 'var(--color-white)',
    secondary: 'var(--color-white)',
    ghost: 'var(--color-slate)',
    ghostWhite: 'var(--color-white)',
  },
  hoverColor: {
    default: 'var(--color-white)',
    secondary: 'var(--color-white)',
    ghost: 'var(--color-slate3)',
    ghostWhite: 'var(--color-slate5)',
  },
};

const SharedStyles = css<StylesProps>`
  appearance: none;
  border: none;
  outline: none;
  font-family: var(--font-osynlig);
  font-feature-settings: 'ss01' on;
  font-weight: normal;
  font-size: ${(props) =>
    props.size === 'small' && props.theme === 'ghost' ? `.875rem` : `${props.size === 'small' ? '.75rem' : '1rem'}`};
  background-color: ${(props) => themes.default[props.theme]};
  color: ${(props) => themes.color[props.theme]};
  stroke: var(--color-citrus);
  padding: ${(props) => (props.size === 'small' ? '.5em 2em' : '.75em 2em')};
  border-radius: 1.5rem;
  line-height: ${(props) => (props.size === 'small' ? '1.7' : '1.5')};
  display: ${(props) => (props.inline ? 'inline-flex' : 'flex')};
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.theme === 'ghost' || props.theme === 'ghostWhite'
      ? `
          padding: 0;
          justify-content: flex-start;
        `
      : ``}

  svg {
    transition: transform 350ms ease;
  }

  .hover &,
  &:hover,
  &:focus {
    background-color: ${(props) => themes.hover[props.theme]};
    ${(props) =>
      (props.theme === 'ghost' || props.theme === 'ghostWhite') &&
      css`
        svg {
          transform: translateX(0.25em);
        }
      `}
    color: ${(props) => themes.hoverColor[props.theme]};
  }

  &:active {
    background-color: ${(props) => themes.active[props.theme]};
    ${(props) =>
      props.theme === 'ghost' || props.theme === 'ghostWhite'
        ? `
            opacity: 0.8;
          `
        : `
            color: var(--color-white80);
          `}
  }
`;

const StyledButton = styled.button<StylesProps>`
  ${SharedStyles};
`;

const StyledLink = styled(AniLink)<StylesProps>`
  ${SharedStyles};
`;

const StyledExternal = styled.a<StylesProps>`
  ${SharedStyles};
`;

const StyledFakeButton = styled.div<StylesProps>`
  ${SharedStyles};
`;

const Button: React.FC<ButtonProps> = ({
  children,
  size,
  showArrow,
  theme = 'default',
  target,
  to,
  href,
  onClick,
  className,
  onMouseEnter,
  onMouseLeave,
  inline,
  extraStyle,
  fake,
}) => {
  let ButtonComponent: ButtonType = StyledButton;

  if (to) {
    ButtonComponent = StyledLink;
  }

  if (href) {
    ButtonComponent = StyledExternal;
  }

  if (fake) {
    ButtonComponent = StyledFakeButton;
  }

  return (
    <ButtonComponent
      className={className}
      size={size}
      theme={theme}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={extraStyle}
      bg="var(--color-citrus5)"
      cover
      direction="left"
      inline={inline ? 1 : 0}
      to={to}
      href={href}
      target={target}
      rel={target && target === '_blank' ? 'noopener noreferrer' : null}
      onClick={onClick}
    >
      {children}
      {showArrow && <ArrowRight className="icon" viewBox="0 0 24 24" />}
    </ButtonComponent>
  );
};

export default Button;
