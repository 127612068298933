import React from 'react';
import styled from 'styled-components';
import ReactMarkdownWrapper from './ReactMarkdownWrapper';

import textCleanup from '$utils/textCleanup';

import { MarkdownProps, WrapperProps } from './types';

const StyledDiv = styled.div<WrapperProps>`
  ${({ indented }) =>
    indented &&
    `
      grid-column: 1 / 32;
      margin-top: 1rem;
      width: 100%;

      @media screen and (min-width: 978px) {
        width: 85%;
        grid-column: 3 / 16;
      }`}
`;

const Markdown: React.FC<MarkdownProps> = ({ content, indented = false }) => (
  <StyledDiv indented={indented}>
    <ReactMarkdownWrapper content={textCleanup(content)} />
  </StyledDiv>
);

export default Markdown;
