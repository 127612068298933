import React from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { LogoProps, SvgProps } from './types';
import { Color } from '$utils/Color';

const LogoSvg = styled.svg<SvgProps>`
  height: ${(props) => props.size}rem;
  pointer-events: none;
`;

const StyledAniLink = styled(AniLink)`
  height: 2rem;
`;

const Logo: React.FC<LogoProps> = ({ inverted = false, size = 2, className = '', handleClick }) => (
  <StyledAniLink
    onClick={handleClick}
    bg="var(--color-citrus5)"
    cover
    direction={inverted ? 'right' : 'left'}
    className={className}
    activeClassName="active"
    to="/"
    aria-label="Osynlig AB - till startsidan"
  >
    <LogoSvg size={size} viewBox="0 0 502 122" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m44.375 44.3999c12.1-12.1 29.2-15.9 44.6-11.5-2-7.2-5.9-13.9-11.5-19.6-17.8-17.7-46.5-17.7-64.2 0s-17.7 46.4 0 64.1c5.6 5.6 12.4 9.5 19.6 11.5-4.4-15.3-.6-32.4 11.5-44.5z"
        fill="#f4da00"
      />
      <g fill={inverted ? Color.white : Color.slate}>
        <path d="m76.4751 38.1001c10.2 0 19.9 4 27.0999 11.2 7.2 7.2 11.2 16.9 11.2 27.1s-4 19.9-11.2 27.0999c-7.1999 7.2-16.8999 11.2-27.0999 11.2s-19.9-4-27.1-11.2c-15-14.9999-15-39.2999 0-54.1999 7.2-7.2 16.8-11.2 27.1-11.2zm0-7c-11.6 0-23.2 4.4-32.1 13.3-17.7 17.7-17.7 46.4 0 64.0999 8.9 8.9 20.5 13.3 32.1 13.3s23.2-4.4 32.0999-13.3c17.7-17.6999 17.7-46.3999 0-64.0999-8.8999-8.9-20.4999-13.3-32.0999-13.3z" />
        <path d="m45.375 7c10.2 0 19.9 4 27.1 11.2 15 15 15 39.3 0 54.2-7.2 7.2-16.9 11.2-27.1 11.2s-19.9-4-27.1-11.2c-15-15-15-39.3 0-54.2 7.2-7.2 16.8-11.2 27.1-11.2zm0-7c-11.7 0-23.3 4.4-32.1 13.3-17.7 17.7-17.7 46.4 0 64.1 8.9 8.9 20.5 13.3 32.1 13.3s23.2-4.4 32.1-13.3c17.7-17.7 17.7-46.4 0-64.1-8.9-8.9-20.5-13.3-32.1-13.3z" />
        <path d="m69.675 82.5999c-.6 0-1.3-.2-1.8-.7l-28-28c-1-1-1-2.6 0-3.5 1-1 2.6-1 3.5 0l28 28c1 1 1 2.6 0 3.5-.4.5-1.1.7-1.7.7z" />
        <path d="m79.575 71.6999c-.6 0-1.3-.2-1.8-.7l-27.4-27.6c-1-1-1-2.6 0-3.5 1-1 2.6-1 3.5 0l27.5 27.5c1 1 1 2.6 0 3.5-.5.5-1.1.8-1.8.8z" />
        <path d="m86.0749 57.2c-.6 0-1.3-.2-1.8-.7l-19.5-19.5c-1-1-1-2.6 0-3.5 1-1 2.6-1 3.5 0l19.5 19.5c1 1 1 2.6 0 3.5-.4.5-1.1.7-1.7.7z" />
        <path d="m55.175 89.0999c-.6 0-1.3-.2-1.8-.7l-20-20c-1-1-1-2.6 0-3.5 1-1 2.6-1 3.5 0l20 20c1 1 1 2.6 0 3.5-.4.5-1 .7-1.7.7z" />
        <path d="m235.775 90.2999c-9.7 0-17-4.3-20.5-12-.8-1.8 0-4 1.8-4.8s4 0 4.8 1.8c2.9 6.4 9.1 7.7 13.9 7.7 7.2 0 11.7-3.5 11.7-9.1 0-5-3.9-6.9-12.8-10-9.2-3-18-5.8-18-16.5 0-9.4 7.4-16 17.9-16 7.8 0 14.1 3.7 17.8 10.5 1 1.8.3 3.9-1.4 4.9-1.8 1-3.9.3-4.9-1.4-2.4-4.5-6.3-6.7-11.5-6.7-6.5 0-10.7 3.5-10.7 8.8 0 4.7 2.8 6.3 13.1 9.6 8.4 2.9 17.8 6.2 17.8 16.8-.1 9.7-7.9 16.4-19 16.4z" />
        <path d="m284.375 89.0001c-2 0-3.6-1.6-3.6-3.6v-18.7l-17.6-29.1c-1-1.7-.5-3.9 1.2-5 1.7-1 3.9-.5 5 1.2l15.1 24.9 15-25.2c1-1.7 3.2-2.3 5-1.3 1.7 1 2.3 3.2 1.3 5l-17.8 29.7v18.5c0 2-1.6 3.6-3.6 3.6z" />
        <path d="m473.975 90.3c-16.9 0-29.7-12.6-29.7-29.4s12.6-29.4 29.4-29.4c9.6 0 18.3 4.5 23.4 12.1 1.1 1.7.7 3.9-1 5s-3.9.7-5-1c-3.7-5.6-10.2-8.9-17.4-8.9-12.6 0-22.2 9.5-22.2 22.2s9.7 22.2 22.5 22.2c10.7 0 18.3-5.7 19.9-14.4h-15.8c-2 0-3.6-1.6-3.6-3.6s1.6-3.6 3.6-3.6h19.7c2 0 3.6 1.6 3.6 3.6.1 14.6-11.4 25.2-27.4 25.2z" />
        <path d="m358.475 88.9997c-1.2 0-2.3-.6-3-1.5l-28.5-40.3v38.3c0 2-1.6 3.6-3.6 3.6s-3.6-1.6-3.6-3.6v-49.7c0-1.6 1-3 2.5-3.4 1.5-.5 3.1.1 4 1.4l28.6 40.2v-38.2c0-2 1.6-3.6 3.6-3.6s3.6 1.6 3.6 3.6v49.7c0 1.6-1 3-2.5 3.4-.4.1-.8.1-1.1.1z" />
        <path d="m409.575 88.9998h-25.5c-2 0-3.6-1.6-3.6-3.6v-50c0-2 1.6-3.6 3.6-3.6s3.6 1.6 3.6 3.6v46.4h21.9c2 0 3.6 1.6 3.6 3.6s-1.6 3.6-3.6 3.6z" />
        <path d="m428.875 88.9998c-2 0-3.6-1.6-3.6-3.6v-50c0-2 1.6-3.6 3.6-3.6s3.6 1.6 3.6 3.6v50c0 2-1.6 3.6-3.6 3.6z" />
        <path d="m176.675 89.9998c-16.3 0-29.1-12.8-29.1-29.1s12.8-29.1 29.1-29.1c16 0 29.1 13 29.1 29.1 0 16.3-12.8 29.1-29.1 29.1zm0-50.9c-12.3 0-21.9 9.6-21.9 21.9s9.6 21.9 21.9 21.9 21.9-9.6 21.9-21.9-9.7-21.9-21.9-21.9z" />
      </g>
    </LogoSvg>
  </StyledAniLink>
);

export default Logo;
