/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { ParallaxProvider } from 'react-scroll-parallax';
import Footer from '$components/Footer';
import Newsletter from '$components/Newsletter';
import '$styles/global.scss';
import { LayoutProps } from './types';
import HeaderWrapper from './HeaderWrapper';
import Cta from '$components/Cta';

const Wrap = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Main = styled.main`
  order: -20;
  flex: 1 1 auto;
`;

const Layout: React.FC<LayoutProps> = ({ children, pageContext }) => {
  const data = useStaticQuery(graphql`
    query {
      cms {
        defaultCta {
          Teasers {
            Heading
            Image {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 240)
                }
              }
            }
            Link {
              Title
              URL
            }
            Text
          }
        }
        footer(publicationState: LIVE) {
          id
          Column1
          Column2
          Column3
          Column4
        }
        stickyMessage {
          Show
          Text
          Link {
            Title
            URL
          }
          Image {
            url
            alternativeText
            imageFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 240)
              }
            }
          }
        }
      }
    }
  `);

  const { page } = pageContext;
  const defaultTeasers = data.cms.defaultCta.Teasers;
  const showTeasers = !page;

  const showNewsletter = !page || page.Newsletter === true;

  const { stickyMessage } = data.cms;

  return (
    <ParallaxProvider>
      <Wrap>
        <HeaderWrapper stickyMessage={stickyMessage} />
        <Main id="main">{children}</Main>
        {showTeasers && <Cta items={defaultTeasers} />}
        {showNewsletter && <Newsletter />}
        <Footer columns={data.cms.footer} />
        <svg xmlns="http://www.w3.org/2000/svg" width="0" height="0">
          <defs>
            <clipPath clipPathUnits="objectBoundingBox" id="contact">
              <rect x={0} y={0} width={1} height={0.4} />
              <ellipse cx={0.5} cy={0.55234375} rx={0.5} ry={0.440625} />
            </clipPath>
          </defs>
        </svg>
      </Wrap>
    </ParallaxProvider>
  );
};

export default Layout;
