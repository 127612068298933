import React from 'react';
import styled from 'styled-components';

const StyledSubline = styled.span`
  color: var(--color-slate3);
  font-family: var(--font-sans);
  font-size: 0.6875em;
  line-height: 1rem;
  letter-spacing: 0;
  text-transform: uppercase;
  font-weight: bold;

  @media screen and (min-width: 978px) {
    letter-spacing: 0.04em;
    line-height: 1.5rem;
  }
`;

const Subline: React.FC = ({ children }) => <StyledSubline>{children}</StyledSubline>;

export default Subline;
