import React from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { MobileMenuItemProps } from './types';

const Item = styled(AniLink)`
  font-family: var(--font-osynlig);
  font-feature-settings: 'ss01' on;
  font-weight: normal;
  display: block;
  font-size: 1.5em;
  position: relative;
  line-height: 2rem;
  color: var(--color-white);
  margin-bottom: 2rem;

  &.active {
    color: var(--color-citrus);
  }

  &.ancestor {
    color: var(--color-citrus5);
  }
`;

const MobileMenuItem: React.FC<MobileMenuItemProps> = ({ to, handleClick, children }) => (
  <Item onClick={handleClick} bg="var(--color-citrus5)" cover direction="right" to={to} activeClassName="active">
    {children}
  </Item>
);

export default MobileMenuItem;
