import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import throttle from 'lodash.throttle';
import CookieWarning from '$components/CookieWarning';
import Logo from '$components/Logo';
import Button from '$components/Button';
import Outer from './Outer';
import MobileMenuWrapper from './MobileMenuWrapper';
import { HeaderProps } from './types';
import MenuWrapper from './MenuWrapper';
import StickyMessage from '$components/StickyMessage';
import MenuItem from './MenuItem';
import MobileMenuButton from './MobileMenuButton';

const FakeHeader = styled.div`
  order: -30;
  width: 100%;
  z-index: -2;
  transition: height 0.42s cubic-bezier(0.31, 0.09, 0.42, 0.97);
  background-color: transparent;
`;

const Menu = styled.nav`
  display: none;

  @media screen and (min-width: 1023px) {
    display: flex;
  }
`;

const ContactButton = styled(Button)`
  @media screen and (max-width: 1022px) {
    display: none;
  }
`;

const Header: React.FC<HeaderProps> = ({
  mobileMenuActive,
  menuButtonClicked,
  onCloseMobileMenu,
  noTransition,
  stickyMessage,
}) => {
  const label = mobileMenuActive ? 'Stäng meny' : 'Öppna meny';
  const menuItems = [
    { to: '/vad-vi-gor/', title: 'Vad vi gör' },
    { to: '/hur-vi-jobbar/', title: 'Hur vi jobbar' },
    { to: '/case/', title: 'Case' },
    { to: '/artiklar/', title: 'Artiklar' },
    { to: '/om-oss/', title: 'Om oss' },
    { to: '/karriar/', title: 'Karriär' },
    { to: '/kontakta-oss/', title: 'Kontakta oss', hideOnDesktop: true },
  ];

  const [showStickyMessage, setShowStickyMessage] = useState(false);
  const [isTransparent, setIsTransparent] = useState(null);
  const [visibleHeader, setVisibleHeader] = useState(true);
  const [cookieBarHeight, setCookieBarHeight] = useState(0);
  const upDelta = -60;
  const downDelta = 20;
  // scroll states
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [currentScrollPos, setCurrentScrollPos] = useState(null);

  // hide navbar on scroll down and show on scroll up but only if menu is closed
  const handleScroll = () => {
    setShowStickyMessage(currentScrollPos < 500);

    if (!mobileMenuActive) {
      // Scroll up
      if (currentScrollPos - prevScrollPos <= upDelta) {
        setVisibleHeader(true);
      }

      // Scroll down
      if (currentScrollPos - prevScrollPos >= downDelta) {
        setVisibleHeader(false);
      }
      // At top
      if (currentScrollPos < 72) {
        setIsTransparent(true);
        setVisibleHeader(true);
      }

      if (currentScrollPos >= 72) {
        setIsTransparent(false);
      }

      setPrevScrollPos(currentScrollPos);
    } else {
      setVisibleHeader(true);
    }
  };

  useEffect(() => {
    handleScroll();
  }, [currentScrollPos]);

  const handleScrollPos = () => {
    setCurrentScrollPos(window.pageYOffset);
  };

  const addListener = () => {
    window.addEventListener('scroll', throttle(handleScrollPos, 200));
  };

  const removeListener = () => {
    window.removeEventListener('scroll', throttle(handleScrollPos, 200));
  };

  useEffect(() => {
    setCurrentScrollPos(window.pageYOffset);
    setVisibleHeader(true);
    addListener();

    return () => removeListener();
  }, []);

  return (
    <>
      <FakeHeader style={{ height: `${cookieBarHeight + 72}px` }} />
      <CookieWarning
        cookieBarHeight={cookieBarHeight}
        setCookieBarHeight={setCookieBarHeight}
        visibleHeader={visibleHeader}
      />
      <Outer visibleHeader={visibleHeader} cookieBarHeight={cookieBarHeight} isTransparent={isTransparent}>
        <MenuWrapper>
          <Logo size={2} />
          <Menu>
            {menuItems.map(({ to, title, hideOnDesktop }) =>
              !hideOnDesktop ? (
                <MenuItem key={to} to={to}>
                  {title}
                </MenuItem>
              ) : null
            )}
          </Menu>
          <ContactButton to="/kontakta-oss/" theme="secondary" size="small">
            Kontakta oss
          </ContactButton>
          <MobileMenuButton
            menuButtonClicked={menuButtonClicked}
            mobileMenuActive={mobileMenuActive}
            label={label}
            dark
          />
        </MenuWrapper>
        <MobileMenuWrapper
          label={label}
          menuItems={menuItems}
          menuButtonClicked={menuButtonClicked}
          mobileMenuActive={mobileMenuActive}
          onCloseMobileMenu={onCloseMobileMenu}
          className={noTransition ? 'no-transition' : ''}
        />
      </Outer>
      {stickyMessage && stickyMessage.Show && (
        <StickyMessage
          showStickyMessage={showStickyMessage}
          Text={stickyMessage.Text}
          Link={stickyMessage.Link}
          Image={stickyMessage.Image}
        />
      )}
    </>
  );
};

export default Header;
