import React from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { MenuItemProps } from './types';
import scalingSize from '$utils/scalingSize';

const Item = styled(AniLink)`
  font-family: var(--font-osynlig);
  font-feature-settings: 'ss01' on;
  font-weight: normal;
  display: block;
  font-size: 0.875em;
  padding: 0.25rem;
  ${scalingSize('margin-left', 8, 28)};
  ${scalingSize('margin-right', 8, 28)};
  color: var(--color-slate);

  &:hover {
    box-shadow: inset 0 -2px 0 0 var(--color-citrus);
  }

  &.${(props) => props.activeClassName} {
    &,
    &:hover {
      box-shadow: inset 0 -2px 0 0 var(--color-slate);
    }
  }
`;

const MenuItem: React.FC<MenuItemProps> = ({ to, children }) => (
  <Item bg="var(--color-citrus5)" cover direction="left" to={to} activeClassName="active">
    {children}
  </Item>
);

export default MenuItem;
