import React from 'react';
import styled from 'styled-components';
import { OuterProps } from './types';

const StyledOuter = styled.header`
  width: 100%;
  height: 4.5rem;
  padding: 0;
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 1000;

  @media screen and (max-width: 1022px) {
    justify-content: space-between;
  }
`;

const Outer: React.FC<OuterProps> = ({ children, visibleHeader, cookieBarHeight, isTransparent }) => (
  <StyledOuter
    style={{
      transform: visibleHeader ? `translateY(${cookieBarHeight}px)` : `translateY(${-cookieBarHeight + -72}px)`,
      backgroundColor: isTransparent ? 'transparent' : 'var(--color-white)',
      transition: isTransparent
        ? 'transform 0.42s cubic-bezier(0.31, 0.09, 0.42, 0.97), background-color 0.2s ease-in'
        : 'transform 0.42s cubic-bezier(0.31, 0.09, 0.42, 0.97), background-color 0.1s ease-in 0.5s',
    }}
  >
    {children}
  </StyledOuter>
);

export default Outer;
