// import React, { useEffect, useState } from 'react';
import React from 'react';
import styled from 'styled-components';
import { WrapMax } from '$components/Wraps';
import dynamicPadding from '$utils/dynamicPadding';
import scalingSize from '$utils/scalingSize';
import { CtaProps, Item } from './types';
import CtaItem from './CtaItem';

const Outer = styled.section<{ single: boolean }>`
  ${dynamicPadding};
  background-color: var(--color-champ4);
  ${(props) => props.single && scalingSize('margin-top', 24, 40)};
  ${(props) => !props.single && scalingSize('margin-top', 2, 20)};
`;

const Inner = styled(WrapMax)`
  display: grid;
  grid-gap: 3.5em;

  @media screen and (min-width: 640px) {
    grid-template-columns: repeat(auto-fit, minmax(31.875em, 1fr));
    grid-gap: 6.111111111vw;
  }
`;

const Cta: React.FC<CtaProps> = ({ item, items }) => (
  <Outer single={item !== null}>
    <Inner>
      {items && items.length && items.map((ctaItem: Item) => <CtaItem key={ctaItem.Heading} item={ctaItem} />)}
      {item && <CtaItem item={item} single />}
    </Inner>
  </Outer>
);

export default Cta;
