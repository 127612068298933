import styled, { css } from 'styled-components';

const Shared = css`
  margin: 0 auto;
  padding-right: 7.222222222vw;
  padding-left: 7.222222222vw;

  @media screen and (min-width: 1440px) {
    padding-right: 6.5rem;
    padding-left: 6.5rem;
  }
`;

const Wrap = styled.div`
  max-width: var(--max-width-wrap);
  ${Shared};
`;

const WrapXs = styled.div`
  max-width: var(--max-width-xs-wrap);
  ${Shared};
`;

const WrapSmall = styled.div`
  max-width: var(--max-width-small-wrap);
  ${Shared};
`;

const WrapMedium = styled.div`
  max-width: var(--max-width-medium-wrap);
  ${Shared};
`;

const WrapLarge = styled.div`
  max-width: var(--max-width-large-wrap);
  ${Shared};
`;

const WrapMax = styled.div`
  max-width: var(--max-width-max-wrap);
  margin: 0 auto;
  padding-right: 7.222222222vw;
  padding-left: 7.222222222vw;

  @media screen and (min-width: 1440px) {
    padding-right: 4.875rem;
    padding-left: 4.875rem;
  }
`;

export { Wrap, WrapSmall, WrapXs, WrapMedium, WrapLarge, WrapMax };
