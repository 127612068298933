import { css, FlattenSimpleInterpolation } from 'styled-components';

const scalingSize = (
  property: string,
  min: number,
  max: number,
  multiplier: number = 1
): FlattenSimpleInterpolation => {
  const vwSize = max / (multiplier * 1440);
  const vwBreakpoint = Math.ceil(min / vwSize) || 0;

  return css`
    ${property}: ${min / 16}rem;

    @media screen and (min-width: ${vwBreakpoint}px) {
      ${property}: ${vwSize * 100}vw;
    }

    @media screen and (min-width: ${multiplier * 1440}px) {
      ${property}: ${max / 16}rem;
    }
  `;
};

export default scalingSize;
