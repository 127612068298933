import React from 'react';
import styled, { css } from 'styled-components';
import scalingSize from '$utils/scalingSize';
import { Heading1Props } from './types';

const StyledHeading1 = styled.h1<Heading1Props>`
  color: var(--color-slate);
  letter-spacing: -0.03em;
  line-height: 1.1;
  ${scalingSize('font-size', 40, 128, 1.430555556)};

  ${(props) =>
    props.primary
      ? css`
          ${scalingSize('margin-top', 40, 88)};
          ${scalingSize('margin-bottom', 32, 72)};
          hyphens: auto;
        `
      : null};

  ${(props) =>
    props.caseHero
      ? css`
          ${scalingSize('width', 280, 880)};
          ${scalingSize('margin-right', 32, 103)};
        `
      : null};

  ${(props) => (props.center ? 'text-align: center' : null)};

  ${(props) =>
    props.markdown
      ? css`
          @media screen and (min-width: 796px) {
            text-align: center;
          }
        `
      : null};

  ${(props) =>
    props.process
      ? css`
          margin: 0 0 0.5rem;

          @media screen and (min-width: 860px) {
            text-align: center;
            grid-column: 1 / 2;
            margin-bottom: 0;
          }
        `
      : null}

  ${(props) =>
    props.startPage || props.notFoundPage
      ? css`
          margin: 0 0 1.5rem;

          @media screen and (min-width: 860px) {
            text-align: center;
            grid-column: 1 / 2;
            margin-bottom: 0;
            margin-right: auto;
            margin-left: auto;
            max-width: 80%;
          }
        `
      : null};
`;

const Heading1: React.FC<Heading1Props> = ({
  children,
  caseHero,
  center,
  startPage,
  primary,
  process,
  markdown,
  notFoundPage,
}) => (
  <StyledHeading1
    center={center}
    startPage={startPage}
    caseHero={caseHero}
    primary={primary}
    process={process}
    markdown={markdown}
    notFoundPage={notFoundPage}
  >
    {children}
  </StyledHeading1>
);

export default Heading1;
