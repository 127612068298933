import React from 'react';
import styled, { css } from 'styled-components';
import { ParagraphProps } from './types';
import scalingSize from '$utils/scalingSize';

const StyledParagraph = styled.p<ParagraphProps>`
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--color-body-text);
  margin: 0 0 1.125rem;

  ${(props) =>
    props.cta || props.article
      ? css`
          margin: 0 0 1.5rem;
        `
      : null};

  ${(props) =>
    props.markdown
      ? css`
          font-size: 1rem;
          line-height: 1.75;
          margin: 0 0 1.5rem;
        `
      : null};

  ${(props) =>
    props.cookie
      ? css`
          font-size: unset;
          margin: 0 0 0.875rem 0;

          @media screen and (min-width: 640px) {
            margin: 0 1.5rem 0 0;
          }
        `
      : null};

  ${(props) =>
    props.quote
      ? css`
          font-weight: 400;
          color: var(--color-slate);
          ${scalingSize('font-size', 16, 20)};
          line-height: 1.55;
          margin: 0.5rem 0 1rem 0;
        `
      : null};
`;

const Paragraph: React.FC<ParagraphProps> = ({ children, markdown, cta, cookie, article, quote }) => (
  <StyledParagraph markdown={markdown} cta={cta} cookie={cookie} article={article} quote={quote}>
    {children}
  </StyledParagraph>
);

export default Paragraph;
