import React from 'react';
import styled from 'styled-components';
import TextInput from '$components/TextInput';
import { NewsletterTextInputProps } from './types';
import Mail from '$assets/icons/mail.svg';

const StyledTextInput = styled(TextInput)`
  width: 100%;
  max-width: 20em;
  margin: 0 auto;

  @media screen and (min-width: 480px) {
    grid-column: 1 / 3;
    max-width: none;
  }

  @media screen and (min-width: 768px) {
    grid-column: 1;
  }
`;

const NewsletterTextInput: React.FC<NewsletterTextInputProps> = ({ onChange, value }) => (
  <StyledTextInput
    onChange={onChange}
    Icon={Mail}
    type="email"
    name="email"
    placeholder="Skriv din epost"
    required
    autocomplete="email"
    value={value}
    rounded
  />
);

export default NewsletterTextInput;
