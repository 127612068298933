import React from 'react';
import styled from 'styled-components';
import Markdown from '$components/Markdown';
import { ColumnProps } from './types';
import Paragraph from './commonStyles';

const StyledAddress = styled.address`
  p {
    ${Paragraph}
    line-height: 3rem;

    @media screen and (min-width: 640px) {
      line-height: 1.625rem;
    }
  }

  h3,
  h5 {
    color: var(--color-cloud3);
    font-size: 1em;
    margin: 0 0 0.875em;
  }
`;

const Address: React.FC<ColumnProps> = ({ content }) => (
  <StyledAddress>
    <Markdown content={content} />
  </StyledAddress>
);

export default Address;
