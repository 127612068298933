import { css } from 'styled-components';

const Paragraph = css`
  color: var(--color-cloud3);
  font-size: 0.875rem;
  line-height: 1.625rem;
  margin-top: 0;
`;

export default Paragraph;
