import React from 'react';
import styled from 'styled-components';
import ContactImage from '$components/ContactImage';
import { ContactCardProps } from './types';
import scalingSize from '$utils/scalingSize';
import MetaContent from './MetaContent';
import MetaItem from './MetaItem';
import Subline from './Subline';

const Card = styled.div`
  color: var(--color-slate);
  text-align: center;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  max-width: 18.75rem;
`;

const Title = styled.h3`
  color: var(--color-slate);
  margin: 0.75em 0 0;
  ${scalingSize('font-size', 11, 18)};
`;

const Content = styled.div`
  width: 100%;
`;

const ContactString = styled.a`
  color: var(--color-slate);

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const ContactCard: React.FC<ContactCardProps> = ({ image, name, role, phone, email, backgroundColor }) => (
  <Card>
    <ContactImage image={{ ...image }} backgroundColor={backgroundColor} />
    {name && (
      <Content>
        <Title>{name}</Title>
        <Subline>{role}</Subline>
        {(phone || email) && (
          <MetaContent>
            {phone && (
              <MetaItem>
                <ContactString href={`tel:${phone}`}>{phone}</ContactString>
              </MetaItem>
            )}
            {email && (
              <MetaItem>
                <ContactString href={`mailto:${email}`}>{email}</ContactString>
              </MetaItem>
            )}
          </MetaContent>
        )}
      </Content>
    )}
  </Card>
);

export default ContactCard;
