import { IGatsbyImageData } from 'gatsby-plugin-image';
import { ImageFileProps } from '$types/interfaces/image';

const getImageData = (imageFile: ImageFileProps): IGatsbyImageData | undefined => {
  if (imageFile?.childImageSharp?.gatsbyImageData) {
    return imageFile.childImageSharp.gatsbyImageData;
  }

  return undefined;
};
export default getImageData;
