import React from 'react';
import styled, { css } from 'styled-components';
import { ExcerptProps } from './types';

const StyledExcerpt = styled.p<ExcerptProps>`
  margin: 1em 0 2em;
  color: var(--color-body-text);

  ${(props) =>
    props.startPage || props.hero
      ? css`
          line-height: 1.75;
          font-size: unset;
          margin: 0;

          @media screen and (min-width: 860px) {
            grid-column: 2;
          }
        `
      : null};

  ${(props) =>
    props.markdown
      ? css`
          line-height: 1.6;
          font-size: 1.25rem;
        `
      : null};
  ${(props) =>
    props.notFoundPage
      ? css`
          margin: 0 0 1em;
          margin-top: 0;
        `
      : null};
`;

const Excerpt: React.FC<ExcerptProps> = ({ children, startPage, hero, markdown, cookie, notFoundPage }) => (
  <StyledExcerpt startPage={startPage} hero={hero} markdown={markdown} cookie={cookie} notFoundPage={notFoundPage}>
    {children}
  </StyledExcerpt>
);

export default Excerpt;
