import React from 'react';
import styled from 'styled-components';
import MobileMenuItem from './MobileMenuItem';
import { WrapMax } from '$components/Wraps';
import Logo from '$components/Logo';
import MobileMenuButton from './MobileMenuButton';
import { MobileMenuWrapperProps } from './types';

const MENU_TIMING = 400;

const MenuWrap = styled(WrapMax)`
  margin-top: 0.5rem;
`;

const StyledMobileMenuWrapper = styled.nav<{ active: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  pointer-events: none;
  height: 100vh;
  clip-path: inset(-20em 0 -20em 100vw);
  transition: all ${MENU_TIMING}ms cubic-bezier(0.12, 0.48, 0.42, 0.96);
  padding-bottom: 4em;
  overflow: hidden;
  visibility: hidden;

  &.no-transition {
    transition: none;
  }

  ${MenuWrap} {
    transition: all 450ms ease 200ms;
    transform: translateX(2vw);
  }

  .mobile-menu-active & ${MenuWrap} {
    transform: none;
  }

  &:before {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 999vh;
    background-color: var(--color-slate);
    content: '';
    opacity: 0;
    transition: opacity 0s linear ${MENU_TIMING}ms;
  }

  ${(props) =>
    props.active &&
    `
    visibility: visible;
    clip-path: inset(-20em 0 -20em 0);
    pointer-events: all;

    &:before {
      opacity: 1;
      transition: opacity 0s linear;
    }
  `}
`;

const MobileMenuInner = styled(WrapMax)`
  display: grid;
  grid-template-areas: 'logo menu contact';
  grid-auto-columns: max-content;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  height: 4.5rem;
  z-index: 2;
  grid-template-areas: 'logo mobile';
  color: var(--color-white);

  @media screen and (max-width: 1139px) {
    grid-template-areas: 'logo mobile';
  }
`;

const MobileMenuWrapper: React.FC<MobileMenuWrapperProps> = ({
  label,
  menuItems,
  className,
  onCloseMobileMenu,
  menuButtonClicked,
  mobileMenuActive,
}) => (
  <StyledMobileMenuWrapper className={className} active={mobileMenuActive}>
    <MobileMenuInner>
      <Logo size={2} handleClick={onCloseMobileMenu} inverted />
      <MobileMenuButton
        menuButtonClicked={menuButtonClicked}
        mobileMenuActive={mobileMenuActive}
        label={label}
        dark={false}
      />
    </MobileMenuInner>
    <MenuWrap>
      {menuItems.map(({ to, title }) => (
        <MobileMenuItem key={to} to={to} handleClick={onCloseMobileMenu}>
          {title}
        </MobileMenuItem>
      ))}
    </MenuWrap>
  </StyledMobileMenuWrapper>
);

export default MobileMenuWrapper;
