import React from 'react';
import styled from 'styled-components';

const StyledUnorderedList = styled.ul`
  line-height: 1.6;
  margin: 1em 0 2em;
  color: var(--color-body-text);

  li {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 640px) {
    padding-left: 1.5rem;
  }
`;

const UnorderedList: React.FC = ({ children }) => <StyledUnorderedList>{children}</StyledUnorderedList>;

export default UnorderedList;
