import React from 'react';
import styled from 'styled-components';

const StyledBlockquote = styled.blockquote`
  padding-left: 1.25rem;
  color: var(--color-slate);
  border-left: 3px solid var(--color-citrus4);
  padding-top: 0.5rem;
  padding-bottom: 0.2rem;

  p {
    color: var(--color-slate);
    line-height: 1.55;
    font-weight: 400;
  }

  @media screen and (min-width: 769px) {
    padding-left: 2em;
  }
`;

const Blockquote: React.FC = ({ children }) => <StyledBlockquote>{children}</StyledBlockquote>;

export default Blockquote;
