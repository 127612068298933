import React from 'react';
import styled from 'styled-components';
import { WrapSmall } from '$components/Wraps';

const StyledInner = styled(WrapSmall)`
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 100%;
  grid-gap: 2em 1em;

  @media screen and (min-width: 480px) {
    grid-template-columns: 1fr 12.75em;
  }

  @media screen and (min-width: 960px) {
    grid-gap: 3.333333333vw 1.666666667vw;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 12.75em;
  }

  @media screen and (min-width: 884px) {
    grid-template-columns: 20em 1fr 12.75em;
  }

  @media screen and (min-width: 1440px) {
    grid-gap: 3em 1.5em;
  }
`;

const Inner: React.FC = ({ children }) => <StyledInner>{children}</StyledInner>;

export default Inner;
