import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import Button from '$components/Button';
import ContactCard from '$components/ContactCard';
import Message from './Message';
import { StickyMessageProps } from './types';

const Outer = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 3;

  @media screen and (min-width: 812px) {
    bottom: 1.25rem;
    right: 1.25rem;
  }
`;

const Inner = styled(AniLink)`
  transition: transform 0.42s cubic-bezier(0.31, 0.09, 0.42, 0.97);
  display: flex;
  align-items: flex-end;
  margin-left: 1rem;
  flex-shrink: 1;
`;

const ContactWrap = styled.div`
  display: flex;
  align-items: flex-end;
  flex-shrink: 0;
  width: 3.5rem;
  height: 4.5rem;
`;

const StickyMessage: React.FC<StickyMessageProps> = ({ showStickyMessage, Text, Link, Image }) => {
  const who = {
    coworker: {
      Name: '',
      Role: '',
      Image,
    },
    BackgroundColor: null,
  };

  const [showMessage, setShowMessage] = useState(showStickyMessage);

  useEffect(() => {
    setShowMessage(showStickyMessage);
  }, [showStickyMessage]);

  return (
    <Outer>
      <Inner
        cover
        to={Link.URL}
        direction="left"
        bg="var(--color-citrus5)"
        style={{ transform: showMessage ? `translateX(0)` : `translateX(500px)` }}
      >
        <Message>
          <p>{Text}</p>
          <Button fake theme="ghostWhite" size="small" to={Link.URL} showArrow>
            {Link.Title}
          </Button>
        </Message>
        <ContactWrap>
          <ContactCard
            key={who.coworker.Name}
            image={who.coworker.Image}
            name={who.coworker.Name}
            role={who.coworker.Role}
            backgroundColor={who.BackgroundColor}
          />
        </ContactWrap>
      </Inner>
    </Outer>
  );
};

export default StickyMessage;
