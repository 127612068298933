interface IColor {
  [color: string]: string;
}

export const Color: IColor = {
  begonia: '#ff4a49',
  begonia2: '#ff5c5b',
  begonia3: '#ff7877',
  begonia4: '#ffa4a4',
  begonia5: '#ffd2d1',
  citrus: '#f4da00',
  citrus2: '#f5dd19',
  citrus3: '#f7e440',
  citrus4: '#f9ec7f',
  citrus5: '#fcf6bf',
  slate: '#252931',
  slate2: '#3a3e45',
  slate3: '#5c5f65',
  slate4: '#929498',
  slate5: '#c8c9cb',
  cloud: '#d7d8de',
  cloud2: '#dbdbe1',
  cloud3: '#e1e2e7',
  cloud4: '#ebebee',
  cloud5: '#f5f5f7',
  champ: '#ece5d4',
  champ2: '#f1ecdf',
  champ3: '#f1ecdf',
  champ4: '#f5f2e9',
  champ5: '#faf8f4',
  white: '#fff',
  white80: 'rgba(255, 255, 255, .8)',
  white56: 'rgba(255, 255, 255, .56)',
  white32: 'rgba(255, 255, 255, .4)',
  white16: 'rgba(255, 255, 255, .16)',
  iris: '#005f6a',
  iris2: '#196f78',
  iris3: '#408790',
  iris4: '#7fafb4',
  iris5: '#bfd7da',
  black: '#000000',
};

export const ContactCardColor: IColor = {
  begonia4: Color.begonia4,
  begonia5: Color.begonia5,
  citrus4: Color.citrus4,
  citrus5: Color.citrus5,
  champ4: Color.champ4,
  champ5: Color.champ5,
  iris4: Color.iris4,
  iris5: Color.iris5,
};
