import React from 'react';
import styled from 'styled-components';
import { BoxProps } from './types';

const StyledBox = styled.input`
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
`;

const Box: React.FC<BoxProps> = ({ id, name, value, required, checked, onChange }) => (
  <StyledBox
    id={id}
    type="checkbox"
    name={name}
    value={value}
    required={required}
    defaultChecked={checked}
    onChange={onChange}
    className="checkbox"
  />
);

export default Box;
