import React from 'react';
import styled from 'styled-components';

const StyledOrderedList = styled.ol`
  line-height: 1.6;
  margin: 1em 0 2em;
  color: var(--color-body-text);
  padding-left: 1em;

  p + & {
    margin-top: -1em;
  }

  li {
    padding-left: 0.5rem;

    p {
      margin: 0;

      p + & {
        margin-top: 1em;
      }
    }
  }

  li + li {
    margin-top: 1em;
  }
`;

const OrderedList: React.FC = ({ children }) => <StyledOrderedList>{children}</StyledOrderedList>;

export default OrderedList;
