import React from 'react';
import styled from 'styled-components';

const StyledMetaItem = styled.li`
  display: inline-block;

  &:after {
    content: '·';
    margin: 0 0.25em;
  }

  &:last-child:after {
    display: none;
  }
`;

const MetaItem: React.FC = ({ children }) => <StyledMetaItem>{children}</StyledMetaItem>;

export default MetaItem;
