import React, { useEffect, useState } from 'react';
import Header from '$components/Header';
import { HeaderWrapperProps } from './types';

const HeaderWrapper: React.FC<HeaderWrapperProps> = ({ stickyMessage }) => {
  const TIMING = 450;

  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [noTransition, setNoTransition] = useState(false);

  useEffect(() => {
    setNoTransition(false);
  }, []);

  const onToggleMobileMenu = () => {
    setMobileMenuActive(!mobileMenuActive);
  };

  const onCloseMobileMenu = (event: React.BaseSyntheticEvent) => {
    const isActive = event.target.classList.contains('active');
    if (!isActive) {
      setNoTransition(true);
      setTimeout(() => {
        setMobileMenuActive(false);
        setTimeout(() => {
          setNoTransition(false);
        }, 50);
      }, TIMING);
    }
    if (isActive) {
      setNoTransition(false);
      setMobileMenuActive(false);
    }
  };

  useEffect(() => {
    document.documentElement.classList[!mobileMenuActive ? 'remove' : 'add']('mobile-menu-active');
  }, [mobileMenuActive]);

  return (
    <Header
      noTransition={noTransition}
      menuButtonClicked={onToggleMobileMenu}
      mobileMenuActive={mobileMenuActive}
      onCloseMobileMenu={onCloseMobileMenu}
      stickyMessage={stickyMessage}
    />
  );
};

export default HeaderWrapper;
